import React from 'react'
import Layout from '../../components/help/Layout/Layout'
import Seo from '../../components/help/Seo'
import Header from '../../components/ui/Header/Header'
import HeaderFixed from './../../components/ui/HeaderFixed/HeaderFixed'
import Intro from '../../components/ui/Intro/Intro'
import Management from '../../components/pages/Shareholders/Management/Management'
import PageNavigation from '../../components/ui/PageNavigation/PageNavigation'
import { graphql, useStaticQuery } from 'gatsby'
import { getHrefToHome } from '../../utils/getHrefToHome'

const ShareholdersPage = () => {
    const lang = 'hy'

    const hrefHome = getHrefToHome(lang)

    const result = useStaticQuery(graphql`
        {
            title: datoCmsRenshinPageShareholder(title: { eq: "Title" }, locale: { eq: "hy" }) {
                value
            }

            text: datoCmsRenshinPageShareholder(title: { eq: "Text" }, locale: { eq: "hy" }) {
                value
            }

            titleSmall: datoCmsRenshinPageShareholder(title: { eq: "Title small" }, locale: { eq: "hy" }) {
                value
            }

            blockLeft: datoCmsRenshinPageShareholder(title: { eq: "Block left" }, locale: { eq: "hy" }) {
                value
                photo {
                    alt
                    url
                }
            }

            blockRight: datoCmsRenshinPageShareholder(title: { eq: "Block right" }, locale: { eq: "hy" }) {
                value
                photo {
                    alt
                    url
                }
            }

            experienceTitle: datoCmsRenshinPageShareholder(title: { eq: "Experience title" }, locale: { eq: "hy" }) {
                value
            }

            slides: allDatoCmsRenshinPageShareholder(filter: { title: { regex: "/Experience text/" }, locale: { eq: "hy" } }) {
                nodes {
                    title
                    value
                }
            }

            seo: datoCmsRenshinSeo(pageName: { eq: "Shareholders" }, locale: { eq: "hy" }) {
                titleH1
                crumbsName
                bgDesktop {
                    url
                }
                bgTabletA {
                    url
                }
                bgTabletP {
                    url
                }

                bgMobile {
                    url
                }
                footerLinks {
                    title
                    url
                }
                seo {
                    title
                    description
                }
            }

            main: datoCmsRenshinSeo(pageName: { eq: "Main" }, locale: { eq: "hy" }) {
                crumbsName
            }
        }
    `)

    const photo = result.seo
        ? {
              1920: result.seo.bgDesktop.url,
              1440: result.seo.bgDesktop.url,
              1024: result.seo.bgTabletA?.url,
              768: result.seo.bgTabletP?.url,
              360: result.seo.bgMobile?.url
          }
        : false

    const breadCrumbs = [
        { name: `${result.main.crumbsName}`, href: `${hrefHome}`, current: false },
        { name: `${result.seo.crumbsName}`, current: true }
    ]

    return (
        <Layout lang={lang}>
            <Seo title={result.seo?.seo?.title} description={result.seo?.seo?.description} />
            <Header light lang={lang} />
            <HeaderFixed lang={lang} light />
            <Intro crumbs={breadCrumbs} title={`${result.seo.titleH1}`} photo={photo} lang={lang} />
            <Management data={result} lang={lang} />
            <PageNavigation links={result.seo.footerLinks} lang={lang} miniTopPadding />
        </Layout>
    )
}

export default ShareholdersPage
